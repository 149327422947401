import Carousel, { Modal, ModalGateway } from 'react-images';
import Gallery from 'react-photo-gallery'
import React, { useState, useCallback } from 'react';
import { array, node, number, func, string, object, shape } from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const BlogPostTemplate = ({
  alias,
  birthday,
  career,
  content,
  contentComponent,
  dominantSide,
  galleryImages,
  height,
  helmet,
  highlights,
  image,
  roles,
  weight,
  youtubeVideo,
}) => {
  const PostContent = contentComponent || Content

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  function gcd(a, b) {
    return (b) ? gcd(b, a % b) : a;
  }
  const decimalToFraction = (_decimal) => {
    if (_decimal === parseInt(_decimal)) {
      return {
        top: parseInt(_decimal),
        bottom: 1,
        display: parseInt(_decimal) + '/' + 1
      };
    } else {
      let top = _decimal.toString().includes(".") ? _decimal.toString().replace(/\d+[.]/, '') : 0;
      const bottom = Math.pow(10, top.toString().replace('-','').length);
      if (_decimal >= 1) {
        top = +top + (Math.floor(_decimal) * bottom);
      } else if (_decimal <= -1) {
        top = +top + (Math.ceil(_decimal) * bottom);
      }
      const x = Math.abs(gcd(top, bottom));
      return {
        top: (top / x),
        bottom: (bottom / x),
        display: (top / x) + '/' + (bottom / x)
      };
    }
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  
  const photos = [];
  galleryImages && galleryImages.map(img => {
    const { top, bottom } = decimalToFraction(img.childImageSharp.fluid.aspectRatio);
    return photos.push({
      src: img.childImageSharp.fluid.src,
      width: top,
      height: bottom,
    });
  });  

  return (
    <div>
      {helmet || ''}
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      <section className="pt5 pt6-l bg-black cover" style={{ backgroundImage: 'url(/img/prole-bg.jpg)'}}>
        <div className="tc pv4 pv5-ns ph4">
          <h2 className="mt0 mb2 f3 f1-l white">
            Profissionais Prole Sports
          </h2>
        </div>
      </section>
      <section>
        <div className="mw7-ns center pa2 pa3-m pa4-l flex flex-wrap">
          <div className="w-100 w-30-m w-40-l ph5 ph4-m pt4 pa0-l">
            <div className="aspect-ratio aspect-ratio--3x4 w-100 bg-dark-gray br3 overflow-hidden">
              <div className="aspect-ratio--object cover" style={{ backgroundImage: `url(/img/${image})`}} />
            </div>
          </div>
          <div className="w-100 w-70-m w-60-l pl3-m pl6-l tc tl-ns">
            <h1 className="mb2 black fw3">{alias}</h1>
            <p className="mb3 ttu f7 gray">
              {roles.join(', ')}
              {!roles.includes('Técnico') && <span> - {birthday}</span>}
            </p>
            <ul className="list flex flex-wrap mb3 justify-center justify-start-ns">
              {highlights.map((highlight) => (
                <li key={highlight + `tag`} className="mb2 br2 f7 lh-solid bg-yellow black ph2 pv1 b ttu mr2">
                  {highlight}
                </li>
              ))}
            </ul>
            <ul className="list ma0 lh-copy">
              {height && <li className="mb1">Altura: <span className="b">{height}m</span></li>}
              {weight && <li className="mb1">Peso: <span className="b">{weight}kg</span></li>}
              {dominantSide && <li className="mb1">Chute: <span className="b">{dominantSide}</span></li>}
              {career && <li className="mb1">
                Times em que atuou:
                <div className="pre">
                  {career}
                </div>
              </li>}
            </ul>
          </div>
          <div className="w-100 pt5">
            {youtubeVideo && (
              <div className="aspect-ratio aspect-ratio--16x9 w-100 bg-dark-gray br3 overflow-hidden mb3">
                <div className="aspect-ratio--object overflow-hidden">
                  <iframe
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    frameBorder={0}
                    height="396"
                    src={youtubeVideo}
                    title={`Video de ${alias}`}
                    width="704"
                    className="w-100 mb4"
                  />
                </div>
              </div>
            )}
            <PostContent content={content} />
            {photos.length > 0 && (
              <section>
                <h2 className="mt0 mb3 f4">
                  Galeria de Fotos
                </h2>
                <Gallery photos={photos} onClick={openLightbox} />
              </section>
            )}
          </div>
        </div>
      </section>
    </div>
  )
}

BlogPostTemplate.propTypes = {
  alias: string.isRequired,
  birthday: string.isRequired,
  career: string,
  content: node.isRequired,
  contentComponent: func,
  height: number,
  helmet: object,
  highlights: array,
  image: string,
  roles: array,
  weight: number,
  youtubeVideo: string,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data
  console.log(post)
  return (
    <Layout>
      <BlogPostTemplate
        alias={post.frontmatter.alias}
        birthday={post.frontmatter.birthday}
        career={post.frontmatter.career}
        content={post.html}
        contentComponent={HTMLContent}
        dominantSide={post.frontmatter.dominant_side}
        galleryImages={post.frontmatter.galleryImages}
        height={post.frontmatter.height}
        highlights={post.frontmatter.highlights}
        image={post.frontmatter.image ? post.frontmatter.image.relativePath : null}
        roles={post.frontmatter.roles}
        youtubeVideo={post.frontmatter.youtube_video}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.alias}`} - Prole Sports</title>
            <meta
              name="description"
              content={`Conheça ${post.frontmatter.alias}, profissional Prole Sports`}
            />
          </Helmet>
        }
        weight={post.frontmatter.weight}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: shape({
    markdownRemark: object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query MemberByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        alias
        birthday(formatString: "YYYY")
        career
        dominant_side
        height
        highlights
        image {
          relativePath
        }
        roles
        youtube_video
        galleryImages {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
